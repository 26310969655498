// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'clarity:home-line'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'About Us',
    icon: <Iconify icon={'ion:business-outline'} {...ICON_SIZE} />,
    path: '/about-us',
  },
  {
    title: 'Education',
    path: '/education',
    icon: <Iconify icon={'cil:education'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Academics',
        items: [
          { title: 'Teachers', path: '/coming-soon' },
          { title: 'Curriculum', path: '/coming-soon' },
          { title: 'Calendar', path: '/coming-soon' },
        ],
      },
      {
        subheader: 'Core Programs',
        items: [
          { title: 'Infants', path: '/coming-soon' },
          { title: 'Toddlers', path: '/coming-soon' },
          { title: 'Twos', path: '/coming-soon' },
          { title: 'Preschool', path: '/coming-soon' },
          { title: 'Pre-K', path: '/coming-soon' },
        ],
      },
      {
        subheader: 'Growth Programs',
        items: [
          { title: 'School Age', path: '/coming-soon' },
          { title: 'Before/After School', path: '/coming-soon' },
          { title: 'Drop-in Care', path: '/coming-soon' },
          { title: 'Spring Break', path: '/coming-soon' },
          { title: 'Summer Camp', path: '/coming-soon' },
          { title: 'Learning at home', path: '/coming-soon' },
          { title: 'Grow Fit', path: '/coming-soon' },
        ],
      },
      {
        subheader: 'Dashboard',
        items: [{ title: 'Dashboard', path: '/coming-soon' }],
      },
    ],
  },
  {
    title: 'Blog',
    icon: <Iconify icon={'carbon:blog'} {...ICON_SIZE} />,
    path: '/blog',
  },
  {
    title: 'Contact',
    icon: <Iconify icon={'cil:contact'} {...ICON_SIZE} />,
    path: 'contact-us',
  },
];

export default menuConfig;
